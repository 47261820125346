
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'OndutyAnalysis'
})
@KeepAlive
export default class OndutyAnalysis extends Vue {
  radio = 'realtime'

  created () {
    // do something
  }

  handleChange (value: string) {
    this.$router.replace('/ondutyanalysis/' + value)
  }
}
